import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
  static targets = [
    "elementEstimate",
    "valueWidth",
    "selectedWidth",
    "toggleInputArea",
    "togglePriceTable", "toggleVariation", "toggleExpress", "togglePackaging", "toggleInsidePrint",
    "streamInputTable", "streamInputAssist", "streamTooltip", "streamEstimate", "streamError", "streamCartNotice"
  ];

  connect() {
    this.procHash = {}
    this.nameHash = {
      marble: "マーブル加工", colorful: "カラフル加工",
      "2c": "2色加工", "3c": "3色加工", "4c": "4色加工"
    };

    // update addEventListener
    this.togglePriceTableTarget.addEventListener("click", this.updateEstimate.bind(this));
    this.toggleInputAreaTarget.addEventListener("keyup", this.updateEstimate.bind(this), { passive: true });
    this.toggleInputAreaTarget.addEventListener("click", this.updateEstimate.bind(this), { passive: true });
    this.toggleInputAreaTarget.addEventListener("change", this.updateEstimate.bind(this), { passive: true });

    // dispatchEvent
    this.element.addEventListener('streamTooltip', this.streamTooltip.bind(this));
    this.element.addEventListener('updateEstimate', this.updateEstimate.bind(this));

    // Assist Area addEventListener
    this.streamInputTableTarget.addEventListener('click', (event) => {
      if (event.target.classList.contains('qty_field')) {
        this.handleTextAreaClick(event.target);
      }
    });


    document.addEventListener("turbo:before-stream-render", this.resetForm.bind(this));
  }

  updateEstimate() {
    if (!this.isDataLoaded) {
      const productKey = this.data.get("products");
      const priceListData = JSON.parse(localStorage.getItem('priceListData'));
      this.productHash = priceListData[productKey].productHash;
      this.optionHash = JSON.parse(localStorage.getItem('optionPriceData'));
      this.isDataLoaded = true;
    }

    const valueWidth = this.valueWidthTarget?.getAttribute('data-width');
    this.selectedWidthTarget.value = valueWidth
    const valueVariation = this.toggleVariationTarget ? parseInt(this.toggleVariationTarget.value, 10) : 1;
    const { totalQty, minQtyFlag } = this.getTotalQty();
    if (this.errorCheck(totalQty, minQtyFlag, valueWidth, valueVariation)) {
      return;
    }

    const estimateArray = [];
    let totalPrice = 0;
    let optionPrice = 0;
    let delivery_flag = false;

    // 基本費用の計算
    estimateArray.push(this.getBasePrice(totalQty, valueWidth));

    const packaging = this.togglePackagingTarget.checked;
    const express = this.hasToggleExpressTarget ? this.toggleExpressTarget.checked : false;
    const insidePrint = this.hasToggleInsidePrintTarget ? this.toggleInsidePrintTarget.checked : false;

    if (express) {
      estimateArray.push(this.getExpressPrice(totalQty, valueWidth));
      delivery_flag = true;
    }

    for (const [key, valuee] of Object.entries(this.procHash)) {
      if (!["solid", "1c", "0c", "full"].includes(key)) {
        const optionUnitPrice = this.getPrice(this.optionHash[key], valuee);
        optionPrice = optionUnitPrice * valuee;
        console.log([this.nameHash[key], valuee, optionUnitPrice, optionPrice])
        estimateArray.push([this.nameHash[key], valuee, optionUnitPrice, optionPrice]);
      }
    }

    if (valueVariation >= 2) {
      estimateArray.push(["バリエーション追加", valueVariation - 1, "5,000", (valueVariation -
        1) * 5000]);
    }

    if (packaging) {
      const optionPackaging = this.getPrice(this.optionHash["packaging"], totalQty);
      estimateArray.push(["個別包装", totalQty, optionPackaging, totalQty *
        optionPackaging
      ]);
    }

    if (insidePrint) {
      const optionInsidePrint = this.getPrice(this.optionHash["inside_print"],
        totalQty);
      estimateArray.push(["うち面加工", totalQty, optionInsidePrint, totalQty *
        optionInsidePrint
      ]);
      delivery_flag = true;
    }

    if (delivery_flag || totalQty > 10000) {
      $(".js_estimate_delivery").css("display", "none");
      $(".js_estimate_nonedelivery").css("display", "block");
    } else {
      $(".js_estimate_delivery").css("display", "block");
      $(".js_estimate_nonedelivery").css("display", "none");
    }

    $(".auto_estimate_li").remove();

    for (const elem of estimateArray) {
      const li = $('<li class="auto_estimate_li">');
      const liName = $("<p>").text(elem[0]);
      const liQty = $("<p>").text(elem[1].toLocaleString());
      const liUnit = $("<p>").text(elem[2]);
      const liSub = $("<p>").text(elem[3].toLocaleString());
      li.append(liName);
      li.append(liQty);
      li.append(liUnit);
      li.append(liSub);
      $(".bl_estimateList").append(li);
      totalPrice += elem[3];
    }

    // ポイント計算
    const getPoint = (totalPrice - (totalPrice % 100)) / 100 * 3

    // 合計金額とポイント表示
    $("#js_estimate_totalPrice").html(totalPrice.toLocaleString());
    $("#js_estimate_returnPoint").html(getPoint.toLocaleString());

    // バリエーションに関するメッセージ表示
    const orderListLength = $(".order_list").length;
    const jsEstimateVariation = $(".js_estimate_variation");

    if (orderListLength >= 2) {
      jsEstimateVariation.empty();
      const text = "※ 現在の仕様はデザイン1種類、全" + orderListLength +
        "バリエーション(色替え、周囲替え)です。デザイン,幅替えは別注文となります。";
      jsEstimateVariation.append(text);
    } else {
      jsEstimateVariation.empty();
    }

  }

  // 基本費用の計算
  getBasePrice(totalQty, width) {
    const baseUnitPrice = this.getPrice(this.productHash[width], totalQty);
    const basePrice = totalQty * baseUnitPrice;
    return ["基本費用", totalQty, baseUnitPrice, basePrice];
  }

  getExpressPrice(totalQty, width) {
    const baseUnitPrice = this.getPrice(this.productHash[width], totalQty);
    let baseExpressUnitPrice = baseUnitPrice * 0.2;
    const baseExpressPrice = totalQty * baseExpressUnitPrice;
    if (Number.isInteger(baseExpressUnitPrice)) {
      baseExpressUnitPrice = baseExpressUnitPrice.toFixed(0);
    } else {
      baseExpressUnitPrice = baseExpressUnitPrice.toFixed(1);
    }
    return ["納期短縮/品質優先", totalQty, baseExpressUnitPrice, baseExpressPrice];
  }

  getTotalQty() {
    let totalQty = 0;
    let minQtyFlag = false;
    this.procHash = {}

    // tr 要素を全て取得
    const rows = this.streamInputTableTarget.querySelectorAll("tr");

    rows.forEach(row => {
      const variationNumber = row.id;
      const num = variationNumber.slice(-1);

      // 各 input 要素の値を取得
      const searchQty = document.querySelector(`#product_product_details_attributes_${num}_quantity`).value;
      const body_proc = document.querySelector(`#product_product_details_attributes_${num}_body_proc`).value;
      const print_proc = document.querySelector(`#product_product_details_attributes_${num}_print_proc`).value;
      
      // procHash を更新
      this.updateProcHash(body_proc, searchQty);
      this.updateProcHash(print_proc, searchQty);

      totalQty += Number(searchQty);

      if (Number(searchQty) < 50) {
        minQtyFlag = true;
      }
    });

    return { totalQty, minQtyFlag };
  }

  updateProcHash(procKey, searchQty) {
    this.procHash[procKey] = (this.procHash[procKey] || 0) + Number(searchQty);
  }

  getPrice(targetHash, searchQty) {
    let targetPrice = 0;
    if (targetHash !== undefined) {
      for (let key in targetHash) {
        if (key <= Number(searchQty)) {
          targetPrice = targetHash[key];
        }
      }
    }
    return targetPrice;
  }


  errorCheck(totalQty, minQtyFlag, valueWidth, valueVariation) {
    let errorFlag = false;
    this.streamErrorTarget.innerHTML = '';

    if (valueWidth === undefined || valueWidth === null) {
      this.createMessage('幅が選択されていません');
      errorFlag = true;
    }

    if (totalQty < 50 || totalQty % 50 !== 0) {
      if (totalQty < 50) {
        this.createMessage(`入力本数が50本未満です。現在${totalQty}本`);
      } else if (totalQty % 50 !== 0) {
        this.createMessage(`注文は50本単位です。 現在${totalQty}本`);
      }
      errorFlag = true;
    }

    if (minQtyFlag && valueVariation >= 2) {
      this.createMessage('1バリエーション当たりの最小数量は50本〜です');
      errorFlag = true;
    }

    this.switchNitice(errorFlag)
  }

  createMessage(message) {
    let messageElement = document.createElement('p');
    messageElement.textContent = message;
    this.streamErrorTarget.appendChild(messageElement);
  }

  switchNitice(errorFlag) {
    if (!errorFlag) {
      this.streamErrorTarget.classList.add("hidden");
      this.elementEstimateTarget.classList.remove("hidden");
      return false;
    } else {
      this.streamErrorTarget.classList.remove("hidden");
      this.elementEstimateTarget.classList.add("hidden");
      return true;
    }
  }


  streamVariationTable() {
    const valueVariation = this.toggleVariationTarget ? parseInt(this.toggleVariationTarget.value, 10) : 1;
    const inputTemplate = this.streamInputTableTarget.querySelector('tr').outerHTML;

    this.streamInputTableTarget.innerHTML = "";
    this.streamInputAssistTarget.innerHTML = "";

    for (let i = 0; i < valueVariation; i++) {
      const trNumber = i;
      const newInput = inputTemplate
        .replace(/add_order_0/g, `add_order_${trNumber}`)
        .replace(/\[0\]/g, `[${trNumber}]`)
        .replace(/_0_/g, `_${trNumber}_`)
        .replace('data-id="0"', `data-id="${trNumber}"`)
        .replace('data-default="default" style="display:none;"', "");
      this.streamInputTableTarget.insertAdjacentHTML('beforeend', newInput);
    }
    this.streamErrorTarget.innerHTML = '';
    this.createMessage('バリエーションが変更されたため数量を再入力してください');
    this.switchNitice(true)
  }

  extractNumberFromId(textAreaId) {
    const matches = textAreaId.match(/\d+/);
    if (matches) {
      return parseInt(matches[0], 10);
    }
    return null;
  }

  handleTextAreaClick(textArea) {
    const targetTextAreaId = textArea.id;
    const number = this.extractNumberFromId(targetTextAreaId);
    const numberAssistContainer = this.streamInputAssistTarget;
    numberAssistContainer.innerHTML = "";

    // 数値入力補助タグを生成
    [50, 100, 500, 1000].forEach(value => {
      const span = document.createElement('span');
      span.textContent = `+${value.toLocaleString()}`;
      span.classList.add('bl_numberAssist_item');
      span.dataset.value = value;
      span.dataset.target = targetTextAreaId;
      numberAssistContainer.appendChild(span);

      span.addEventListener('click', () => {
        const valueToAdd = parseInt(span.dataset.value);
        const currentValue = parseInt(textArea.value) || 0;
        textArea.value = (currentValue + valueToAdd).toString();
      });
    });

    // 「Clear」ボタンを生成
    const clearButton = document.createElement('span');
    clearButton.textContent = 'Clear';
    clearButton.classList.add('bl_numberAssist_item', 'bl_numberAssist_item__clear');
    numberAssistContainer.appendChild(clearButton);

    clearButton.addEventListener('click', () => {
      textArea.value = '';
    });

    const p = document.createElement('p');
    p.innerHTML = `<strong>${number + 1}種類目</strong>を操作：`;
    if (numberAssistContainer.firstChild) {
      numberAssistContainer.insertBefore(p, numberAssistContainer.firstChild);
    } else {
      numberAssistContainer.appendChild(p);
    }
  }

  streamTooltip() {
    const tooltip = this.streamTooltipTarget;

    if (tooltip.classList.contains('is-visible')) {
      return;
    }
    tooltip.classList.add('is-visible');
    tooltip.style.opacity = 0;
    tooltip.style.transform = 'translateY(10px)';
    tooltip.style.transition = 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out';
    tooltip.style.display = 'block';

    setTimeout(() => {
      tooltip.style.opacity = 1;
      tooltip.style.transform = 'translateY(0)';
    }, 0);

    setTimeout(() => {
      tooltip.style.opacity = 0;
      tooltip.style.transform = 'translateY(10px)';
    }, 5000);

    setTimeout(() => {
      tooltip.style.display = 'none';
      tooltip.classList.remove('is-visible');
    }, 5300);
  }


  resetForm() {
    const orderBoxElement = this.streamInputTableTarget
    if (orderBoxElement) {
      while (orderBoxElement.childElementCount > 1) {
        orderBoxElement.removeChild(orderBoxElement.lastElementChild);
      }
    }

    // フォームをリセット
    const estimateForm = document.getElementById("estimate_form");
    if (estimateForm) {
      estimateForm.reset();
    }

    // 特定の要素の中身を空にする
    if (this.streamInputAssistTarget) {
      this.streamInputAssistTarget.innerHTML = "";
    }

    // 'th_checked' と 'checked' のクラスを削除
    document.querySelectorAll('#js_tablePrice_warp [class*="th_checked"]').forEach(element => {
      element.classList.remove('th_checked');
    });

    document.querySelectorAll('#js_tablePrice_warp [class*="checked"]').forEach(element => {
      element.classList.remove('checked');
    });

    // IDが"js_data_width"の要素のdata-width属性を削除
    this.valueWidthTarget.removeAttribute('data-width');

    // 製品選択と幅選択のアニメーションをリセット
    const resetSelectionAnimation = (elementId) => {
      const selectionElement = document.getElementById(elementId);
      if (selectionElement) {
        const selection = selectionElement.querySelector('.selection');
        if (selection) {
          selection.style.transform = 'translateX(0px)';
        }
      }
    };

    resetSelectionAnimation("estimate_product");
    resetSelectionAnimation("estimate_width");


    const fadeOutAndIn = () => {     
      this.createMessage('ページを更新せず次の商品入力が可能です');
      this.streamErrorTarget.classList.remove("hidden");
      this.elementEstimateTarget.classList.add("hidden");
    
      // フェードイン
      this.streamCartNoticeTarget.style.opacity = 1;
      this.streamCartNoticeTarget.style.display = "block";
    
      // 5秒後にフェードアウト
      setTimeout(() => {
        let opacity = 1;
        const fadeOutInterval = setInterval(() => {
          if (opacity > 0) {
            opacity -= 0.05;
            this.streamCartNoticeTarget.style.opacity = opacity;
          } else {
            clearInterval(fadeOutInterval);
            this.streamCartNoticeTarget.style.display = "none";
          }
        }, 50);
      }, 3000);
    };
    
    fadeOutAndIn();

  }


}
