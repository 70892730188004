import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="item-variant"
export default class extends Controller {
  static targets = ["variants"];

  connect() {
    console.log ("aaaa")
  }

  addVariant() {
    const newVariant = document.createElement("div");
    newVariant.innerHTML = `
      <input type="text" name="item[variant][]" placeholder="Variant">
      <input type="number" name="item[min_qty][]" placeholder="Min Quantity">
      <input type="number" name="item[max_qty][]" placeholder="Max Quantity">
      <input type="number" name="item[unit_price][]" placeholder="Unit Price">
    `;
    this.variantsTarget.appendChild(newVariant);
  }
}
